:root {
  --tram: #f0be32;
  --trolleybus: #1e9641;
  --bus: #cd2837;

  --dark-first: #0e1525;
  --dark-second: #181818;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  text-align: center;
  font: 20px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;

  background-color: var(--dark-second);
  color: white;
}

main {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.content {
  font-size: 26px;
  max-width: 800px;
}

img {
  display: inline;

  vertical-align: sub;
  border-style: none;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  gap: 5px;
}

.vehicle {
  margin-top: 5px;
  margin-bottom: 5px;
}

.line {
  pointer-events: all;
  user-select: none;

  width: 90px;
  height: 38px;

  color: #fff;
  padding: 2px;
  border-radius: 0.25rem;

  transition: opacity 0.3s;
  transition-timing-function: ease-in-out;
}

.line[data-completed="false"] {
  opacity: 0.35;
}

.line[data-completed="true"] {
  opacity: 1;
}

.tram {
  background-color: var(--tram);
}

.trolleybus {
  background-color: var(--trolleybus);
}

.bus {
  background-color: var(--bus);
}

dialog form > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

dialog {
  background-color: #f7f7f7;
  color: #363636;
  border-radius: 6px;
  border: #dbdbdb;
  border-color: #dbdbdb;
  padding: 10px 30px;
}

dialog > header:first-child {
  background-color: #efefef;
  border-radius: 6px 6px 0 0;
  margin: -10px -30px 10px;
  padding: 10px;
  text-align: center;
}

input[type="submit"] {
  font-size: 1em;

  display: block;

  width: 50px;
  height: 35px;
  padding: 5px;
  margin: 0 auto;
}

.delete {
  pointer-events: all;
  user-select: none;
  cursor: pointer;

  float: right;
}
