:root {
  --tram: #f0be32;
  --trolleybus: #1e9641;
  --bus: #cd2837;
  --dark-first: #0e1525;
  --dark-second: #181818;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  text-align: center;
  background-color: var(--dark-second);
  color: #fff;
  font: 20px / 1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}

main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.content {
  max-width: 800px;
  font-size: 26px;
}

img {
  vertical-align: sub;
  border-style: none;
  display: inline;
}

.list {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.vehicle {
  margin-top: 5px;
  margin-bottom: 5px;
}

.line {
  pointer-events: all;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  border-radius: .25rem;
  width: 90px;
  height: 38px;
  padding: 2px;
  transition: opacity .3s ease-in-out;
}

.line[data-completed="false"] {
  opacity: .35;
}

.line[data-completed="true"] {
  opacity: 1;
}

.tram {
  background-color: var(--tram);
}

.trolleybus {
  background-color: var(--trolleybus);
}

.bus {
  background-color: var(--bus);
}

dialog form > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

dialog {
  color: #363636;
  background-color: #f7f7f7;
  border: #dbdbdb;
  border-radius: 6px;
  padding: 10px 30px;
}

dialog > header:first-child {
  text-align: center;
  background-color: #efefef;
  border-radius: 6px 6px 0 0;
  margin: -10px -30px 10px;
  padding: 10px;
}

input[type="submit"] {
  width: 50px;
  height: 35px;
  margin: 0 auto;
  padding: 5px;
  font-size: 1em;
  display: block;
}

.delete {
  pointer-events: all;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  float: right;
}

/*# sourceMappingURL=index.106a995b.css.map */
